import React from 'react';

const ExampleTournamentSeriesSchedule = () => (
  <>
    <b>Example Tournament Series Schedule</b>
    <table className="information-table">
      <thead>
        <tr>
          <th>Months</th>
          <th>Tournament Series</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>April - May</td>
          <td>Spring Tournament Series</td>
        </tr>
        <tr>
          <td>October - November</td>
          <td>Fall Tournament Series</td>
        </tr>
        <tr>
          <td>November</td>
          <td>National Championship</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default ExampleTournamentSeriesSchedule;
