import React from 'react';
import ExampleTournamentSeriesSchedule from './example-tournament-series-schedule';

const WhatIsACodeChampionshipTournamentSeries = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          A Code Championship Tournament Series is a series of four tournaments
          in four weeks (it&apos;s ok to miss one or two).
        </p>
      </div>
      <div style={{ marginRight: '20px' }}>
        <ExampleTournamentSeriesSchedule />
      </div>
    </div>
  </>
);

export default WhatIsACodeChampionshipTournamentSeries;
