import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import VideoSection from './video-section';
import NavigationButton from './navigation-button';

const OurStory = ({ showButton }) => (
  <article>
    <div className="two-wide-container">
      <div>
        <p>
          We are a group of highly energetic teachers and software developers who
          believe that all students should learn a little bit about code.
          Even if they&apos;re not professional software developers,
          <i> - and we don&apos;t believe everyone has to be - </i>
          code is going to be such an important part of their lives,
          that they should know at least a little bit about it.
        </p>
        <p>
          We started with
          {' '}
          <Link to="/abamath/">
            robotics competitions in 2014
          </Link>
          , and if we&apos;ve learned anything over the past seven years of teaching kids
          to code, it is that
          <b> Learning follows Excitement</b>
          .
        </p>
        <p>
          Competitive computer coding is our way
          to bring a sports-like mentality to code.
        </p>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/n4L4gWoZJBU?rel=0"
          title="Code Championship at Minnedemo 2019"
        />
      </div>
    </div>
    {
      showButton && (
        <NavigationButton
          path="/about/"
          text="Learn More About Us"
        />
      )
    }
  </article>
);

OurStory.propTypes = {
  showButton: PropTypes.bool,
};

OurStory.defaultProps = {
  showButton: false,
};

export default OurStory;
