import React from 'react';
import { Link } from 'gatsby';
import unbalancedTournamentWithPoints from '../images/instructions/unbalanced-tournament-with-points.png';

const WhoIsInvitedToTheNationalChampionship = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Winning a tournament during a tournament series earns a coder a spot
          in the National Championship.
        </p>
        <p>
          If one coder wins multiple tournaments,
          another player will also be able to go to the national championship
          based on points earned during the series.
        </p>
        <p>
          This can get complicated, but some people (like us) really like knowing the details.
          If you are curious to know more, you can click this link to
          {' '}
          <Link to="/scoring-details">learn more about scoring.</Link>
        </p>
      </div>
      <div>
        <img
          src={unbalancedTournamentWithPoints}
          style={{ width: '100%', maxWidth: '500px', margin: '10px' }}
          alt="Unbalanced Tournament with Points"
        />
      </div>
    </div>
  </>
);

export default WhoIsInvitedToTheNationalChampionship;
