import React from 'react';

import StandardOutboundLink from './standard-outbound-link';

const SocialMediaLinks = () => (
  <ul>
    <li>
      <StandardOutboundLink href="https://www.youtube.com/channel/UCU8es_rNn7WpLrA2qvH62JQ">
        YouTube
      </StandardOutboundLink>
    </li>
    <li>
      <StandardOutboundLink href="https://twitter.com/codechampionshp">
        Twitter
      </StandardOutboundLink>
    </li>
    <li>
      <StandardOutboundLink href="https://www.facebook.com/codechampionship/">
        Facebook
      </StandardOutboundLink>
    </li>
    <li>
      <StandardOutboundLink href="https://www.instagram.com/codechampionship/">
        Instagram
      </StandardOutboundLink>
    </li>
  </ul>
);

export default SocialMediaLinks;
