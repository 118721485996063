import React from 'react';

const ExampleTournamentSchedule = () => (
  <>
    <b>Example Tournament Schedule</b>
    <table className="information-table">
      <thead>
        <tr>
          <th>Time</th>
          <th>Activity</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>8:55</td>
          <td>Players enter and get ready</td>
        </tr>
        <tr>
          <td>9:00</td>
          <td>Walk through event plan</td>
        </tr>
        <tr>
          <td>9:05</td>
          <td>Introduce challenge</td>
        </tr>
        <tr>
          <td>9:10</td>
          <td>Build and help time</td>
        </tr>
        <tr>
          <td>9:40</td>
          <td>Pool play</td>
        </tr>
        <tr>
          <td>10:10</td>
          <td>Build and help time</td>
        </tr>
        <tr>
          <td>10:45</td>
          <td>Tournament play</td>
        </tr>
        <tr>
          <td>10:55</td>
          <td>Congratulations and goodbyes</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default ExampleTournamentSchedule;
