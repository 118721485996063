import React from 'react';
import VideoSection from './video-section';

const WhatIsACodeChampionshipTournament = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          A &quot;Bot&quot; is a virtual robot.
          Each game is different, so each bot is different.
          Some bots move, some shoot, some drive, some drill,
          some fly, some eat, some duel...
        </p>
        <p>
          Players write the code for their bot and then we run the bots
          head-to-head against one another to determine who wins the match!
          Here is an example of Alex and Quinn&apos;s bots playing head-to-head.
        </p>
      </div>
      <div>
        <VideoSection
          videoId="tUj_W5jH1H4"
          title="Alex vs Quinn - Inside Code Championship"
        />
      </div>
    </div>
  </>
);

export default WhatIsACodeChampionshipTournament;
