import React from 'react';
import StandardOutboundLink from './standard-outbound-link';

const QuoteStripeZach = () => (
  <div className="stripe-hr">
    <q>
      This is great. Our students enjoyed it and last year we got some kids
      coding who don&apos;t normally engage with computer science. Huge win.
    </q>
    <br />
    <br />
    - Zach, Teacher at
    {' '}
    <StandardOutboundLink href="https://internationalschoolmn.com/" className="white-link">
      International School of Minnesota
    </StandardOutboundLink>
  </div>
);

export default QuoteStripeZach;
