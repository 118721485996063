import React from 'react';
import Layout from '../components/layout';

import OurStory from '../components/our-story';
import AboutAbamath from '../components/about-abamath';
import Founders from '../components/founders';
import SEO from '../components/seo';
import QuoteStripeLuke from '../components/quote-stripe-luke';
import WhatIsACodeChampionshipTournament from '../components/what-is-a-code-championship-tournament';
import WhatIsABot from '../components/what-is-a-bot';
import WhatIsCodeChampionship from '../components/what-is-code-championship';
import WhatIsACodeChampionshipTournamentSeries from '../components/what-is-a-code-championship-tournament-series';
import WhoIsInvitedToTheNationalChampionship from '../components/who-is-invited-to-the-national-championship';
import HowDoIJoin from '../components/how-do-i-join';
import DoINeedToKnowHowToCode from '../components/do-i-need-to-know-how-to-code';
import SocialMediaLinks from '../components/social-media-links';
import QuoteStripeZach from '../components/quote-stripe-zach';

const AboutPage = () => (
  <Layout>
    <SEO
      title="About | Learn About Code Championship"
      description="Code Championship is the ultimate competitive computer coding experience! Learn more about us here."
      keywords={['about', 'what is code championship', 'what', 'who']}
    />
    <h1>About</h1>
    <div className="stripe-hr thin" />
    <p>
      Scroll or use the quick links below to learn more about
      Code Championship and the people behind it.
    </p>
    <ul>
      <li>
        <a href="#basics">The Basics</a>
      </li>
      <li>
        <a href="#team">The Code Championship Team</a>
      </li>
      <li>
        <a href="#contact">Get in touch</a>
      </li>
    </ul>
    <h2 id="basics">The Basics</h2>
    <h3>
      What is Code Championship?
    </h3>
    <WhatIsCodeChampionship />
    <h3>
      Do I need to know how to code?
    </h3>
    <DoINeedToKnowHowToCode />
    <h3 style={{ marginTop: '60px' }}>
      What is a Bot?
    </h3>
    <WhatIsABot />
    <h3 style={{ marginTop: '60px' }}>
      What is a Code Championship Tournament?
    </h3>
    <WhatIsACodeChampionshipTournament />
    <h3 style={{ marginTop: '60px' }}>
      What is a Code Championship Tournament Series?
    </h3>
    <WhatIsACodeChampionshipTournamentSeries />
    <h3 style={{ marginTop: '60px' }}>
      Who is invited to the National Championship?
    </h3>
    <WhoIsInvitedToTheNationalChampionship />
    <h3 style={{ marginTop: '60px' }}>
      How do I join?
    </h3>
    <HowDoIJoin />
    <QuoteStripeLuke />
    <h2 id="team">The Code Championship Team</h2>
    <h3>Who We Are</h3>
    <OurStory />
    <h3 style={{ marginTop: '60px' }}>Company</h3>
    <AboutAbamath />
    <h3 style={{ marginTop: '60px' }}>Founders</h3>
    <Founders />
    <QuoteStripeZach />
    <h2 id="contact">Get in Touch</h2>
    <p>Reach out! We read every email!</p>
    <div><b>contact@codechampionship.com</b></div>
    <p>
      We don&apos;t do too much on social media.
      Our mailing list is the best way to stay up to date
      on what we&apos;re up to, but here are our links!
    </p>
    <SocialMediaLinks />
  </Layout>
);

export default AboutPage;
