import React from 'react';
import VideoSection from './video-section';

const WhatIsCodeChampionship = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Fun, competitive, and friendly virtual coding tournaments for
          new and experienced coders from 3rd to 9th grade.
        </p>
        <p>
          Code is Sport! Just like gymnastics 🤸‍♀️, dance 💃, or basketball 🏀.
          Competitive coding is fun and pushes us to be great! Join a tournament 🏆 and
          play head-to-head against other coders! 👩‍💻
        </p>
      </div>
      <div>
        <VideoSection
          title="What is Code Championship?"
          videoId="ks80WhgW294"
        />
      </div>
    </div>
  </>
);

export default WhatIsCodeChampionship;
