import React from 'react';
import ExampleTournamentSchedule from './example-tournament-schedule';

const WhatIsACodeChampionshipTournament = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Code Championship Tournaments are fun and friendly
          virtual coding competitions.
        </p>
        <p>
          These tournaments are hosted online using Google Meet and the Code Championship website.
          If you have a modern browser like Chrome or Firefox, no additional download is required.
          The link to the Google Meet event will is emailed two days before the tournament.
          Every tournament will be a little different,
          but here is an example of a common, 2-hour tournament schedule.
        </p>
      </div>
      <div style={{ marginRight: '20px' }}>
        <ExampleTournamentSchedule />
      </div>
    </div>
  </>
);

export default WhatIsACodeChampionshipTournament;
