import React from 'react';
import NavigationButton from './navigation-button';

const HowDoIJoin = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Joining a Code Championship Tournament Series can be done online in two minutes!
        </p>
      </div>
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Join a Tournament
          </div>
          <div className="panel-body">
            <p>
              Ready to join the fun?
              Register for the Fall Tournament Series.
            </p>
            <NavigationButton
              path="/fall-2024-tournament-series/"
              text="Join the Series"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default HowDoIJoin;
