import React from 'react';

const QuoteStripeLuke = () => (
  <div className="stripe-hr">
    <q>
      Learning follows excitement. So the real question we should be asking is,
      &quot;How do we excite the next generation about code?&quot;
      And that’s where the idea for Code Championship comes from.
    </q>
    <br />
    <br />
    - Luke, Co-Founder of Code Championship
  </div>
);

export default QuoteStripeLuke;
