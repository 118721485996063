import React from 'react';

import VideoSection from './video-section';

const AboutAbamath = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Abamath LLC is the company behind Code Championship.
        </p>
        <p>
          We started as a Math Tutoring center.
          We started offering competitive robotics programs in 2014.
        </p>
        <p>You can see from the video that it was a pretty exciting program!</p>
        <p>
          We wanted to reach more coders, but our robotics program was hard to grow,
          since we needed to buy new robots for our new coders.
          So we developed Code Championship!
        </p>
        <p>
          Today, we put most of our time and energy into making
          Code Championship as great as it can be, but if you have any questions for
          Abamath LLC, please reach out to info@abamath.com
        </p>
      </div>
      <div>
        <VideoSection
          src="https://www.youtube.com/embed/0JSgVT5kgZI?rel=0"
          title="Abamath Robotics 2014"
        />
      </div>
    </div>
  </>
);

export default AboutAbamath;
