import React from 'react';
import NavigationButton from './navigation-button';

const DoINeedToKnowHowToCode = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          No experience or preparation is required,
          but if you would like to see what a tournament challenge will be like,
          head to our Play Now page and give some of the challenges a try!
        </p>
        <p>
          We don&apos;t disclose which challenge we will be competing in
          until the day of the tournament,
          but it will be one of the challenges on that page!
          To get started, we recommend watching the video, then trying the Level 0 challenge!
        </p>
      </div>
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Play Now
          </div>
          <div className="panel-body">
            <p>Build a Code Championship Bot to play against other coders.</p>
            <NavigationButton
              path="/code/"
              text="Play Now"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default DoINeedToKnowHowToCode;
