import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import StandardOutboundLink from './standard-outbound-link';

const Founders = () => (
  <>
    <div className="two-wide-container">
      <div>
        <p>
          Lindsey and Luke Schlangen (the two in the middle of the picture)
          started Abamath together in 2013. (You can see them both in the
          robotics video above!)
        </p>
        <p>
          Lindsey and Luke both have their roots in engineering and teaching.
          Lindsey had been leading or teaching for the past 8+ years.
          Luke has been coding or teaching for the past 8+ years.
        </p>
        <p>
          Lindsey and Luke are incredibly passionate about inspiring the
          next generation of coders.
        </p>
        <StandardOutboundLink href="https://www.lukeschlangen.com/">
          LukeSchlangen.com
        </StandardOutboundLink>
      </div>
      <div>
        <StaticImage
          src="../images/about/code-championship-team.png"
          alt="Code Championship Instruction Team"
        />
      </div>
    </div>
  </>
);

export default Founders;
